import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { useTheme } from '@mui/material';
import { ReactComponent as PlusIconComponent } from '@icons/wolfkit-light/plus-light.svg';
import { ReactComponent as VisaIconComponent } from '@icons/payment-systems/visa.svg';
import { ReactComponent as ApplePayIconComponent } from '@icons/payment-systems/apple-pay.svg';
import { ReactComponent as MasterCardIconComponent } from '@icons/payment-systems/mastercard.svg';
import { ReactComponent as PayPalIconComponent } from '@icons/payment-systems/paypal.svg';
import { BodySemiBold } from '@components/styled/Typography';
import Icon from '@shared/ui/icons/Icon';
import TooltipLabel from '../TooltipLabel';
const EmptySelectContainer = styled.div(() => ({
    width: '100%',
}));
const EmptySelectButton = styled.button(props => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    color: props.theme.palette.primary.main,
    backgroundColor: props.theme.palette.primary.light,
    cursor: 'pointer',
    userSelect: 'none',
    border: 'none',
    padding: '12px 18px',
    borderRadius: props.theme.baseSize,
    '&:hover': {
        backgroundColor: props.theme.palette.primary.light,
        color: props.theme.palette.primary.main,
    },
}));
const VisaIcon = styled(VisaIconComponent)(props => ({
    width: props.theme.baseSize * 7,
    height: props.theme.baseSize * 4,
    rect: {
        fill: 'transparent',
        stroke: props.theme.palette.primary.main,
    },
    path: {
        fill: props.theme.palette.primary.main,
    },
}));
const ApplePayIcon = styled(ApplePayIconComponent)(props => ({
    width: props.theme.baseSize * 7,
    height: props.theme.baseSize * 4,
    rect: {
        fill: 'transparent',
        stroke: props.theme.palette.primary.main,
    },
    path: {
        fill: props.theme.palette.primary.main,
    },
}));
const PayPalIcon = styled(PayPalIconComponent)(props => ({
    width: props.theme.baseSize * 7,
    height: props.theme.baseSize * 4,
    rect: {
        fill: 'transparent',
        stroke: props.theme.palette.primary.main,
    },
    path: {
        fill: props.theme.palette.primary.main,
    },
}));
const MasterCardIcon = styled(MasterCardIconComponent)(props => ({
    width: props.theme.baseSize * 7,
    height: props.theme.baseSize * 4,
    rect: {
        fill: 'transparent',
        stroke: props.theme.palette.primary.main,
    },
    path: {
        fill: props.theme.palette.primary.main,
    },
    '& :last-child': {
        stroke: props.theme.palette.text.disabled,
    },
}));
const StartButtonElement = styled.span(props => ({
    display: 'flex',
    gap: props.theme.spacing_sizes.s,
}));
const IconsList = styled.span(props => ({
    display: 'inline-flex',
    gap: props.theme.spacing_sizes.m,
}));
const EmptySelect = ({ onMethodAdd }) => {
    const { t } = useTranslation();
    const theme = useTheme();
    return (_jsxs(EmptySelectContainer, { children: [_jsx(TooltipLabel, { labelText: t('settings.tabs.subscription.payment_method.secondary'), tooltipText: t('settings.tabs.subscription.payment_method.secondary') }), _jsxs(EmptySelectButton, { onClick: onMethodAdd, children: [_jsxs(StartButtonElement, { children: [_jsx(Icon, { IconComponent: PlusIconComponent, color: theme.palette.primary.main }), _jsx(BodySemiBold, { children: t('settings.tabs.subscription.payment_method.add_new_method') })] }), _jsxs(IconsList, { children: [_jsx(VisaIcon, {}), _jsx(ApplePayIcon, {}), _jsx(PayPalIcon, {}), _jsx(MasterCardIcon, {})] })] })] }));
};
export default EmptySelect;
